
import Client from "./client";
export default class Divisions extends Client{
    constructor() {
        super();
    }
    setting(){
        return new Promise(function(resolve,reject){
            this.get('public/setting')
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    save(data){
        console.log(data)
        return new Promise(function(resolve,reject){
            this.postFormdata('setting',data)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    willwinns(id){
        return new Promise(function(resolve,reject){
            this.get('setting/willwins/'+id)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    setWillWinns(data){
        return new Promise(function(resolve,reject){
            this.post('setting/setwillwinner',data)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(data){
        return new Promise(function(resolve,reject){
            this.delete(`setting/delete/${data}`)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}
