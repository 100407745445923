
import Client from "./client";
export default class Products extends Client{
    constructor() {
        super();
    }
    lists(){
        return new Promise(function(resolve,reject){
            this.get('product/lists')
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    create(data){
        return new Promise(function(resolve,reject){
            this.post('product/store',data)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    sponsor_lists(id){
        return new Promise(function(resolve,reject){
            this.get('public/sponsor/lists/'+id)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    save(data){
        return new Promise(function(resolve,reject){
            this.postFormdata('sponsor/store',data)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(data){
        return new Promise(function(resolve,reject){
            this.delete(`product/delete/${data}`,data)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}
