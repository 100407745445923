
import City from "./../../api/city";
const city = new City();
export const state = {
    citys: [],
    selectedCity : null,
    state : "LIST",//LIST,CREATE,EDIT
    loading : false,
    message : "",
    error : false,
}

export const mutations = {
    SET_CITYS(state, value) {
        state.citys = value;
    },
    SET_SELECTEDCITY(state, value) {
        state.selectedCity = value;
    },
    SET_STATE(state, value) {
        state.state = value;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_MESSAGE(state, value) {
        state.message = value;
    },
    SET_ERROR(state, value) {
        state.error = value;
    },
}

export const getters = {
    citys(state) {
        return state.citys
    },
    selectedCity(state) {
        return state.selectedCity
    },
    state(state) {
        return state.state
    },
    loading(state) {
        return state.loading
    },
    message(state) {
        return state.message
    },
    error(state) {
        return state.error
    }
}

export const actions = {
     // @ts-ignore
    getCity(context) {
        context.commit('SET_LOADING', true)
        context.commit('SET_MESSAGE', "")
        context.commit('SET_ERROR', false)
        city.getCity().then((res) => {
            context.commit('SET_CITYS', res)
            context.commit('SET_LOADING', false)
            context.commit('SET_MESSAGE', "")
            context.commit('SET_ERROR', false)
            // @ts-ignore
        }).catch((e) => {
            // eslint-disable-next-line no-console
            context.commit('SET_LOADING', false)
            context.commit('SET_MESSAGE', e)
            context.commit('SET_ERROR', true)
        })
    },
    createCity(context,data) {
        context.commit('SET_LOADING', true)
        context.commit('SET_MESSAGE', "")
        context.commit('SET_ERROR', false)
        city.createCity(data).then((res) => {
            context.commit('SET_STATE', "LIST")
            context.commit('SET_MESSAGE', res)
            context.dispatch('getCity');
            // @ts-ignore
        }).catch((e) => {
            // eslint-disable-next-line no-console
            context.commit('SET_LOADING', false)
            context.commit('SET_MESSAGE', e)
            context.commit('SET_ERROR', true)
        })
    },
    deleteCity(context,data) {
        context.commit('SET_LOADING', true)
        context.commit('SET_MESSAGE', "")
        context.commit('SET_ERROR', false)
        city.deleteCity(data).then((res) => {
            context.commit('SET_STATE', "LIST")
            context.commit('SET_MESSAGE', res)
            context.dispatch('getCity');
            // @ts-ignore
        }).catch((e) => {
            // eslint-disable-next-line no-console
            context.commit('SET_LOADING', false)
            context.commit('SET_MESSAGE', e)
            context.commit('SET_ERROR', true)
        })
    },
    
}