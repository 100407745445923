
import Client from "./client";
export default class City extends Client{
    constructor() {
        super();
        this._endpoint = 'city';
    }
    getCity() {
        return new Promise(function (resolve, reject) {
            this.get(this._endpoint)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.data)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    createCity(data){
        return new Promise(function (resolve, reject) {
            this.post(this._endpoint,data)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    deleteCity(id){
        return new Promise(function (resolve, reject) {
            this.delete(this._endpoint+'/'+id)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }

}
