
import Customer from "./../../api/customer";
const customer = new Customer();
export const state = {
    items: [],
    item : null,
    state : "LIST",//LIST,CREATE,EDIT
    loading : false,
    message : "",
    error : false,
}

export const mutations = {
    SET_ITEMS(state, value) {
        state.items = value;
    },
    SET_ITEM(state, value) {
        state.item = value;
    },
    SET_STATE(state, value) {
        state.state = value;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_MESSAGE(state, value) {
        state.message = value;
    },
    SET_ERROR(state, value) {
        state.error = value;
    },
}

export const getters = {
    items(state) {
        return state.items
    },
    item(state) {
        return state.item
    },
    state(state) {
        return state.state
    },
    loading(state) {
        return state.loading
    },
    message(state) {
        return state.message
    },
    error(state) {
        return state.error
    }
}

export const actions = {
     // @ts-ignore
    get(context) {
        context.commit('SET_LOADING', true)
        context.commit('SET_MESSAGE', "")
        context.commit('SET_ERROR', false)
        customer.data().then((res) => {
            context.commit('SET_ITEMS', res)
            context.commit('SET_LOADING', false)
            context.commit('SET_MESSAGE', "")
            context.commit('SET_ERROR', false)
            // @ts-ignore
        }).catch((e) => {
            // eslint-disable-next-line no-console
            context.commit('SET_LOADING', false)
            context.commit('SET_MESSAGE', e)
            context.commit('SET_ERROR', true)
        })
    },
    create(context,data) {
        context.commit('SET_LOADING', true)
        context.commit('SET_MESSAGE', "")
        context.commit('SET_ERROR', false)
        customer.create(data).then((res) => {
            context.commit('SET_STATE', "LIST")
            context.commit('SET_MESSAGE', res)
            context.dispatch('get');
            // @ts-ignore
        }).catch((e) => {
            // eslint-disable-next-line no-console
            context.commit('SET_LOADING', false)
            context.commit('SET_MESSAGE', e)
            context.commit('SET_ERROR', true)
        })
    },
    delete(context,data) {
        context.commit('SET_LOADING', true)
        context.commit('SET_MESSAGE', "")
        context.commit('SET_ERROR', false)
        customer.hapus(data).then((res) => {
            context.commit('SET_STATE', "LIST")
            context.commit('SET_MESSAGE', res)
            context.dispatch('get');
            // @ts-ignore
        }).catch((e) => {
            // eslint-disable-next-line no-console
            context.commit('SET_LOADING', false)
            context.commit('SET_MESSAGE', e)
            context.commit('SET_ERROR', true)
        })
    },
    edit(context,data) {
        context.commit('SET_LOADING', true)
        context.commit('SET_MESSAGE', "")
        context.commit('SET_ERROR', false)
        customer.edit(data).then((res) => {
            context.commit('SET_STATE', "LIST")
            context.commit('SET_MESSAGE', res)
            context.dispatch('get');
            // @ts-ignore
        }).catch((e) => {
            // eslint-disable-next-line no-console
            context.commit('SET_LOADING', false)
            context.commit('SET_MESSAGE', e)
            context.commit('SET_ERROR', true)
        })
    },
    
}