
import Settings from "./../../api/setting";
const setting = new Settings();
export const state = {
    items: [],
    item : null,
    state : "LIST",//LIST,CREATE,EDIT
    loading : false,
    message : "",
    error : false,
    product_id : 0,
    willWinns : []
}

export const mutations = {
    SET_ITEMS(state, value) {
        state.items = value;
    },
    SET_ITEM(state, value) {
        state.item = value;
    },
    SET_STATE(state, value) {
        state.state = value;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    SET_MESSAGE(state, value) {
        state.message = value;
    },
    SET_ERROR(state, value) {
        state.error = value;
    },
    SET_PRODUCT_ID(state,value){
        state.product_id = value;
    },
    SET_WILL_WINNS(state,value){
        state.willWinns = value;
    }
}

export const getters = {
    items(state) {
        return state.items
    },
    item(state) {
        return state.item
    },
    state(state) {
        return state.state
    },
    loading(state) {
        return state.loading
    },
    message(state) {
        return state.message
    },
    error(state) {
        return state.error
    },
    product_id(state) {
        return state.product_id
    },
    willWinns(state) {
        return state.willWinns
    }
}

export const actions = {
     // @ts-ignore
    get(context) {
        return new Promise((resolve,reject)=>{
            context.commit('SET_LOADING', true)
            context.commit('SET_MESSAGE', "")
            context.commit('SET_ERROR', false)
            setting.setting().then((res) => {
                context.commit('SET_ITEM', res)
                context.commit('SET_LOADING', false)
                context.commit('SET_MESSAGE', "")
                context.commit('SET_ERROR', false)
                // @ts-ignore
                resolve(res)
            }).catch((e) => {
                reject(e)
                // eslint-disable-next-line no-console
                context.commit('SET_LOADING', false)
                context.commit('SET_MESSAGE', e)
                context.commit('SET_ERROR', true)
            })
        })
    },
    create(context,data) {
        return new Promise((resolve,reject)=>{
            context.commit('SET_LOADING', true)
            context.commit('SET_MESSAGE', "")
            context.commit('SET_ERROR', false)
            setting.save(data).then((res) => {
                context.commit('SET_STATE', "LIST")
                context.commit('SET_MESSAGE', res)
                resolve(res)
                // @ts-ignore
            }).catch((e) => {
                // eslint-disable-next-line no-console
                context.commit('SET_LOADING', false)
                context.commit('SET_MESSAGE', e)
                context.commit('SET_ERROR', true)
                reject(e)
            })
        })
    },
    getwillwinns(context,id) {
        return new Promise((resolve,reject)=>{
            context.commit('SET_LOADING', true)
            context.commit('SET_MESSAGE', "")
            context.commit('SET_ERROR', false)
            setting.willwinns(id).then((res) => {
                context.commit('SET_WILL_WINNS', res)
                context.commit('SET_LOADING', false)
                context.commit('SET_MESSAGE', "")
                context.commit('SET_ERROR', false)
                // @ts-ignore
                resolve(res)
            }).catch((e) => {
                reject(e)
                // eslint-disable-next-line no-console
                context.commit('SET_LOADING', false)
                context.commit('SET_MESSAGE', e)
                context.commit('SET_ERROR', true)
            })
        })
    },
    createWillWinns(context,data) {
        return new Promise((resolve,reject)=>{
            context.commit('SET_LOADING', true)
            context.commit('SET_MESSAGE', "")
            context.commit('SET_ERROR', false)
            setting.setWillWinns(data).then((res) => {
                context.commit('SET_STATE', "LIST")
                context.commit('SET_MESSAGE', res)
                resolve(res)
                // @ts-ignore
            }).catch((e) => {
                // eslint-disable-next-line no-console
                context.commit('SET_LOADING', false)
                context.commit('SET_MESSAGE', e)
                context.commit('SET_ERROR', true)
                reject(e)
            })
        })
    },
    delete(context,data) {
        return new Promise((resolve,reject)=>{
            context.commit('SET_LOADING', true)
            context.commit('SET_MESSAGE', "")
            context.commit('SET_ERROR', false)
            setting.hapus(data).then((res) => {
                context.commit('SET_STATE', "LIST")
                context.commit('SET_MESSAGE', res)
                resolve(res);
                // @ts-ignore
            }).catch((e) => {
                // eslint-disable-next-line no-console
                context.commit('SET_LOADING', false)
                context.commit('SET_MESSAGE', e)
                context.commit('SET_ERROR', true)
                reject(e)
            })
        })
    },
}