// eslint-disable-next-line no-unused-vars
const axios = require('axios');
import router from "@/router";
const qs = require('querystring')
export default class Client{
    constructor(){
        // this._BASEURL = "http://system.jpcexpress.com/api/";
        this._BASEURL = "https://admin-api.peanutbutterevents.com/api/";
    }
    getToken(){
        let data = localStorage.user
        data = JSON.parse(data)
        return data.token ? data.token : null
    }
    getBaseUrl(){
        return this._BASEURL;
    }
    setBaseUrl(url){
        this._BASEURL=url;
    }// eslint-disable-next-line no-unused-vars
    getAuthConfig(type){
        let token = this.getToken()
        let config = {
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization':"Bearer "+token
            }
        }
        return config
    }
    header(){
        let token = this.getToken()
        let config = {
            headers: {
                'Content-Type': 'form-data',
                'Authorization':"Bearer "+token
            }
        }
        return config
    }
    /**
     * @param {string} endpoint The string
    */
    get(endpoint){
        let config = this.getAuthConfig("")
        return new Promise(function(resolve,reject){
            axios.get(`${this._BASEURL}${endpoint}`,config)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (error) {
                    if(error.response.status  === 401){
                        router.push('/logout')
                    }else if(error.response.status === 400){
                        reject(error)
                    }
                });
        }.bind(this));
    }



    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
    post(endpoint,data){
        let config = this.getAuthConfig("")
        return new Promise(function(resolve,reject){
            axios.post(`${this._BASEURL}${endpoint}`,qs.stringify(data),config)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (error) {
                    if(error.response.status  === 401){
                        router.push('/logout')
                    }else if(error.response.status === 400){
                        reject(error)
                    }
                });
        }.bind(this));
    }

    
    
    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
     postSMS(endpoint,data){
        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization':"XBa69Dp1Tj1YnHevVp2bwtT2is5z0yecFSmA55VtfHzqAIuhPPMXgvp6rBSxu9cZ"
            }
        }
        return new Promise(function(resolve,reject){
            axios.post(endpoint,qs.stringify(data),config)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (error) {
                    if(error.response.status  === 401){
                        router.push('/logout')
                    }else if(error.response.status === 400){
                        reject(error)
                    }
                });
        }.bind(this));
    }

    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
    postLogin(endpoint,data){
        return new Promise(function(resolve,reject){
            axios.post(`${this._BASEURL}${endpoint}`,data)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (err) {
                    return reject(err);
                });
        }.bind(this));
    }


    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
   put(endpoint,data){
        let config = this.getAuthConfig("")
        return new Promise(function(resolve,reject){
            axios.put(`${this._BASEURL}${endpoint}`,qs.stringify(data),config)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (error) {
                    if(error.response.status  === 401){
                        router.push('/logout')
                    }else if(error.response.status === 400){
                        reject(error)
                    }
                });
        }.bind(this));
    }

    /**
     * @param {string} endpoint The string
    */
   delete(endpoint){
        let config = this.getAuthConfig("")
        return new Promise(function(resolve,reject){
            axios.delete(`${this._BASEURL}${endpoint}`,config)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (error) {
                    if(error.response.status  === 401){
                        router.push('/logout')
                    }else if(error.response.status === 400){
                        reject(error)
                    }
                });
        }.bind(this));
    }


    /**
     * @param {string} endpoint The string
     * @param {object} data data
    */
    postFormdata(endpoint,data){
        let config = this.header()
        return new Promise(function(resolve,reject){
            axios.post(`${this._BASEURL}${endpoint}`,data,config)
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (err) {
                    return reject(err);
                });
        }.bind(this));
    }
    putFormdata(endpoint,data){
        return new Promise(function(resolve,reject){
            axios.put(`${this._BASEURL}${endpoint}`,data,{headers:this.header()})
            .then(function (result) {
                    resolve(result.data);
                })
            .catch(function (err) {
                    return reject(err);
                });
        }.bind(this));
    }

}

//module.exports = Client;