
import Client from "./client";
export default class User extends Client{
    constructor() {
        super();
        this._endpointLogin = 'login';
        this._endpointMurid = 'users'
    }
    login(email,password){
        var data = {email,password};
        return new Promise(function(resolve,reject){
            this.postLogin(this._endpointLogin,data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    lists() {
        return new Promise(function (resolve, reject) {
            this.get('member/lists')
            .then((result)=>{
                if (result.success) {
                    resolve(result.data)
                } else {
                    reject(0)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    create(data){
        return new Promise(function(resolve,reject){
            this.post('public/member/register',data)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(data){
        return new Promise(function(resolve,reject){
            this.delete(`member/delete/${data}`,data)
            .then((result)=>{
                resolve(result.data)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
}
