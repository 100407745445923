
import Client from "./client";
export default class Order extends Client{
    constructor() {
        super();
        this._endpoint = 'order';
    }
    sendSms(data){
        return new Promise(function (resolve, reject) {
            this.postSMS('https://sawit.wablas.com/api/sms/send',data)
            .then((result)=>{
                resolve(result)
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    noResi(){
        return new Promise(function (resolve, reject) {
            this.get(this._endpoint+"/resi")
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.data)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    data(data) {
        const {status,tanggal,resi} = data;
        return new Promise(function (resolve, reject) {
            this.get(this._endpoint+"?status="+status+"&resi="+resi+"&tanggal="+tanggal)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.data)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    create(data){
        return new Promise(function (resolve, reject) {
            this.post(this._endpoint,data)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    hapus(id){
        return new Promise(function (resolve, reject) {
            this.delete(this._endpoint+"/"+id)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    updateStatus(data){
        return new Promise(function (resolve, reject) {
            this.put(this._endpoint+"/status",data)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    updateSelesai(data){
        return new Promise(function (resolve, reject) {
            this.put(this._endpoint+"/selesai",data)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    edit(data){
        return new Promise(function (resolve, reject) {
            this.put(this._endpoint,data)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    
    addDelivery(data){
        return new Promise(function (resolve, reject) {
            this.post(this._endpoint+"/delivery",data)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    
    
    addResiVendor(data){
        return new Promise(function (resolve, reject) {
            this.post(this._endpoint+"/resi_vendor",data)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }

    getTracking(id_order){
        return new Promise(function (resolve, reject) {
            this.get(this._endpoint+"/tracking?id_order="+id_order)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.data)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }
    
    addTracking(data){
        return new Promise(function (resolve, reject) {
            this.post(this._endpoint+"/tracking",data)
            .then((result)=>{
                if (result.code == 200) {
                    resolve(result.message)
                } else {
                    reject(result.message)
                }
            })
            .catch(err=>reject(err));
        }.bind(this))
    }

}